// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Gotham-Book.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Gotham-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Gotham-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: 500;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: 700;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
html, body{
    height: auto;
}
body{
    margin: 0 30px;
    background-color: #F1F7F6;
    font-family: 'Gotham', serif;
    font-weight: 400;
}
@media(max-width: 600px){
    body{
        margin: 0 10px;
    }
}
#root{
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,4CAA0C;AAC9C;AACA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,gBAAgB;IAChB,4CAA4C;AAChD;AACA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;IAChB,4CAA0C;AAC9C;AACA;IACI,YAAY;AAChB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,4BAA4B;IAC5B,gBAAgB;AACpB;AACA;IACI;QACI,cAAc;IAClB;AACJ;AACA;IACI,YAAY;AAChB","sourcesContent":["@font-face {\n    font-family: 'Gotham';\n    font-style: normal;\n    font-weight: 400;\n    src: url('./assets/fonts/Gotham-Book.otf');\n}\n@font-face {\n    font-family: 'Gotham Medium';\n    font-style: normal;\n    font-weight: 500;\n    src: url('./assets/fonts/Gotham-Medium.otf');\n}\n@font-face {\n    font-family: 'Gotham Bold';\n    font-style: normal;\n    font-weight: 700;\n    src: url('./assets/fonts/Gotham-Bold.otf');\n}\nhtml, body{\n    height: auto;\n}\nbody{\n    margin: 0 30px;\n    background-color: #F1F7F6;\n    font-family: 'Gotham', serif;\n    font-weight: 400;\n}\n@media(max-width: 600px){\n    body{\n        margin: 0 10px;\n    }\n}\n#root{\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
