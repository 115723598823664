// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageSection_uploadImageInput__fhv0T{
    display: none;
}
.ImageSection_imageSection__rg-Bs{
    position: relative;
    height: 160px;
    width: 160px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    cursor: pointer;
}
.ImageSection_image__v1S88{
    height: 160px;
    width: 160px;
    border-radius: 50%;
    object-fit: cover;
}
.ImageSection_imagePlaceholder__-INdY{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #578E86;
    background-color: #BFE1DC;
    font-size: 44px;
    font-family: 'Gotham Medium', serif;
    border-radius: 50%;
    letter-spacing: -3px;
}
.ImageSection_imagePlaceholderIcon__IA58u{
    height: 33px;
    width: 33px;
}
.ImageSection_overlay__yDOxS{
    position: absolute;
    top: 0;
    left: 0;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%);
}
.ImageSection_camera__R6fem{
    position: absolute;
    height: 18px;
    width: 20px;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/ImageSection.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,mCAAmC;IACnC,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,8EAA8E;AAClF;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".uploadImageInput{\n    display: none;\n}\n.imageSection{\n    position: relative;\n    height: 160px;\n    width: 160px;\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 35px;\n    cursor: pointer;\n}\n.image{\n    height: 160px;\n    width: 160px;\n    border-radius: 50%;\n    object-fit: cover;\n}\n.imagePlaceholder{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    width: 100%;\n    color: #578E86;\n    background-color: #BFE1DC;\n    font-size: 44px;\n    font-family: 'Gotham Medium', serif;\n    border-radius: 50%;\n    letter-spacing: -3px;\n}\n.imagePlaceholderIcon{\n    height: 33px;\n    width: 33px;\n}\n.overlay{\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 160px;\n    width: 160px;\n    border-radius: 50%;\n    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%);\n}\n.camera{\n    position: absolute;\n    height: 18px;\n    width: 20px;\n    bottom: 10px;\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadImageInput": `ImageSection_uploadImageInput__fhv0T`,
	"imageSection": `ImageSection_imageSection__rg-Bs`,
	"image": `ImageSection_image__v1S88`,
	"imagePlaceholder": `ImageSection_imagePlaceholder__-INdY`,
	"imagePlaceholderIcon": `ImageSection_imagePlaceholderIcon__IA58u`,
	"overlay": `ImageSection_overlay__yDOxS`,
	"camera": `ImageSection_camera__R6fem`
};
export default ___CSS_LOADER_EXPORT___;
