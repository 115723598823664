// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginButton_loginButton__b2zUj{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47px;
    padding: 0 26px;
    color: #40798C;
    background-color: #FFFFFF;
    font-size: 14px;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border: 1px solid #40798C66;
    border-radius: 500px;
    cursor: pointer;
}
@media(max-width: 480px){
    .LoginButton_loginButton__b2zUj{
        width: 160px;
    }
}
@media(max-width: 420px){
    .LoginButton_loginButton__b2zUj{
        width: 130px;
    }
}
.LoginButton_person__YQ87F{
    height: 16px;
    width: 16px;
    margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/logged-out/LoginButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,mCAAmC;IACnC,qBAAqB;IACrB,2BAA2B;IAC3B,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI;QACI,YAAY;IAChB;AACJ;AACA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".loginButton{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 47px;\n    padding: 0 26px;\n    color: #40798C;\n    background-color: #FFFFFF;\n    font-size: 14px;\n    font-family: 'Gotham Medium', serif;\n    text-decoration: none;\n    border: 1px solid #40798C66;\n    border-radius: 500px;\n    cursor: pointer;\n}\n@media(max-width: 480px){\n    .loginButton{\n        width: 160px;\n    }\n}\n@media(max-width: 420px){\n    .loginButton{\n        width: 130px;\n    }\n}\n.person{\n    height: 16px;\n    width: 16px;\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginButton": `LoginButton_loginButton__b2zUj`,
	"person": `LoginButton_person__YQ87F`
};
export default ___CSS_LOADER_EXPORT___;
