// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormButton_button__qFTFo{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 45px;
    color: #FFFFFF;
    background-color: #40798C;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border: none;
    border-radius: 500px;
    cursor: pointer;
}
.FormButton_button__qFTFo:focus-visible{
    outline: none;
}
.FormButton_disabled__6apVh{
    background-color: #CFE2E8 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,mCAAmC;IACnC,qBAAqB;IACrB,YAAY;IACZ,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,oCAAoC;AACxC","sourcesContent":[".button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 48px;\n    padding: 0 45px;\n    color: #FFFFFF;\n    background-color: #40798C;\n    font-family: 'Gotham Medium', serif;\n    text-decoration: none;\n    border: none;\n    border-radius: 500px;\n    cursor: pointer;\n}\n.button:focus-visible{\n    outline: none;\n}\n.disabled{\n    background-color: #CFE2E8 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `FormButton_button__qFTFo`,
	"disabled": `FormButton_disabled__6apVh`
};
export default ___CSS_LOADER_EXPORT___;
