// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormCheckbox_container__hIrH0{
    display: block;
}
.FormCheckbox_label__wGcW8{
    display: inline-block;
    position: relative;
    top: -9.5px;
    color: #1F363D;
    margin-left: 7px;
    font-size: 14px;
    line-height: 14px;
}
.FormCheckbox_checkbox__7ih3A[type="checkbox"]{
    height: 24px;
    width: 24px;
    border: 1px solid #1F363D33;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.FormCheckbox_checkbox__7ih3A[type="checkbox"]:checked{
    color: #FFFFFF;
    background-color: #40798C;
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: center;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormCheckbox.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,WAAW;IACX,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,wBAAwB;IACxB,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,wBAAwB;IACxB,4BAA4B;IAC5B,2BAA2B;AAC/B","sourcesContent":[".container{\n    display: block;\n}\n.label{\n    display: inline-block;\n    position: relative;\n    top: -9.5px;\n    color: #1F363D;\n    margin-left: 7px;\n    font-size: 14px;\n    line-height: 14px;\n}\n.checkbox[type=\"checkbox\"]{\n    height: 24px;\n    width: 24px;\n    border: 1px solid #1F363D33;\n    border-radius: 4px;\n    appearance: none;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n}\n.checkbox[type=\"checkbox\"]:checked{\n    color: #FFFFFF;\n    background-color: #40798C;\n    background-size: 60% 60%;\n    background-repeat: no-repeat;\n    background-position: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormCheckbox_container__hIrH0`,
	"label": `FormCheckbox_label__wGcW8`,
	"checkbox": `FormCheckbox_checkbox__7ih3A`
};
export default ___CSS_LOADER_EXPORT___;
