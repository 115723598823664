// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormDropdown_container__uRr4u{
    display: inline-block;
}
.FormDropdown_label__qKW5n{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.FormDropdown_asterisk__Sh5t9{
    margin-left: 5px;
    color: #EB443F;
}
.FormDropdown_dropdownContainer__pLNg3{
    position: relative;
}
.FormDropdown_select__0TZoz{
    padding: 20px 24px 20px 50px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 5px top;
}
.FormDropdown_noImage__Jrga3{
    padding-left: 24px;
}
.FormDropdown_select__0TZoz:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.FormDropdown_error__nk1Ka{
    border: 1px solid #EB443F;
}
.FormDropdown_select__0TZoz option{
    color: #1F363D;
    font-size: 14px;
    font-family: 'Gotham', serif;
}
.FormDropdown_image__4IhJi{
    position: absolute;
    top: 15px;
    left: 20px;
    height: 24px;
    width: 24px;
}
.FormDropdown_downCaret__4cwqM{
    position: absolute;
    top: 25px;
    right: 24px;
    height: 7.5px;
    width: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormDropdown.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,4BAA4B;IAC5B,cAAc;IACd,yBAAyB;IACzB,2BAA2B;IAC3B,oBAAoB;IACpB,gBAAgB;IAChB,wBAAwB;IACxB,qBAAqB;IACrB,kCAAkC;AACtC;AACA;IACI,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,cAAc;IACd,eAAe;IACf,4BAA4B;AAChC;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,aAAa;IACb,WAAW;AACf","sourcesContent":[".container{\n    display: inline-block;\n}\n.label{\n    display: block;\n    color: #1F363D80;\n    margin-left: 10px;\n    margin-bottom: 8px;\n    font-size: 14px;\n    line-height: 14px;\n}\n.asterisk{\n    margin-left: 5px;\n    color: #EB443F;\n}\n.dropdownContainer{\n    position: relative;\n}\n.select{\n    padding: 20px 24px 20px 50px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 500px;\n    appearance: none;\n    -webkit-appearance: none;\n    -moz-appearance: none;\n    background-position: right 5px top;\n}\n.noImage{\n    padding-left: 24px;\n}\n.select:focus{\n    border: 1px solid #40798C;\n    outline: none;\n    box-shadow: 0 3px 12px 0 #40798C12;\n}\n.error{\n    border: 1px solid #EB443F;\n}\n.select option{\n    color: #1F363D;\n    font-size: 14px;\n    font-family: 'Gotham', serif;\n}\n.image{\n    position: absolute;\n    top: 15px;\n    left: 20px;\n    height: 24px;\n    width: 24px;\n}\n.downCaret{\n    position: absolute;\n    top: 25px;\n    right: 24px;\n    height: 7.5px;\n    width: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormDropdown_container__uRr4u`,
	"label": `FormDropdown_label__qKW5n`,
	"asterisk": `FormDropdown_asterisk__Sh5t9`,
	"dropdownContainer": `FormDropdown_dropdownContainer__pLNg3`,
	"select": `FormDropdown_select__0TZoz`,
	"noImage": `FormDropdown_noImage__Jrga3`,
	"error": `FormDropdown_error__nk1Ka`,
	"image": `FormDropdown_image__4IhJi`,
	"downCaret": `FormDropdown_downCaret__4cwqM`
};
export default ___CSS_LOADER_EXPORT___;
