// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormError_error__Cu-RF{
    display: block;
    margin-top: 5px;
    text-align: center;
    color: #EB443F;
    font-size: 14px;
    scroll-margin: 100px;
}
.FormError_warning__G5g\\+h{
    position: relative;
    top: 2px;
    height: 16px;
    width: 16px;
    padding-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormError.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".error{\n    display: block;\n    margin-top: 5px;\n    text-align: center;\n    color: #EB443F;\n    font-size: 14px;\n    scroll-margin: 100px;\n}\n.warning{\n    position: relative;\n    top: 2px;\n    height: 16px;\n    width: 16px;\n    padding-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `FormError_error__Cu-RF`,
	"warning": `FormError_warning__G5g+h`
};
export default ___CSS_LOADER_EXPORT___;
