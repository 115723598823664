// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchInput_container__n--QC{
    display: inline-block;
}
.SearchInput_inputContainer__rBf2W{
    position: relative;
}
.SearchInput_input__YAKvO{
    height: 16px;
    padding: 15px 24px 15px 55px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
}
.SearchInput_input__YAKvO::placeholder{
    color: #1F363D80;
}
.SearchInput_input__YAKvO:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.SearchInput_magnifyingGlass__U-3hb{
    position: absolute;
    top: 11px;
    left: 20px;
    height: 24px;
    width: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchInput.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,4BAA4B;IAC5B,cAAc;IACd,yBAAyB;IACzB,2BAA2B;IAC3B,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;AACf","sourcesContent":[".container{\n    display: inline-block;\n}\n.inputContainer{\n    position: relative;\n}\n.input{\n    height: 16px;\n    padding: 15px 24px 15px 55px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 500px;\n}\n.input::placeholder{\n    color: #1F363D80;\n}\n.input:focus{\n    border: 1px solid #40798C;\n    outline: none;\n    box-shadow: 0 3px 12px 0 #40798C12;\n}\n.magnifyingGlass{\n    position: absolute;\n    top: 11px;\n    left: 20px;\n    height: 24px;\n    width: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SearchInput_container__n--QC`,
	"inputContainer": `SearchInput_inputContainer__rBf2W`,
	"input": `SearchInput_input__YAKvO`,
	"magnifyingGlass": `SearchInput_magnifyingGlass__U-3hb`
};
export default ___CSS_LOADER_EXPORT___;
