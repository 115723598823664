// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormDonateMoneyInput_container__d\\+LV3{
    display: inline-block;
}
.FormDonateMoneyInput_inputContainer__zy7OZ{
    position: relative;
}
.FormDonateMoneyInput_input__hHRZW{
    height: 16px;
    padding: 20px 24px 20px 50px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
}
.FormDonateMoneyInput_input__hHRZW::placeholder{
    color: #1F363D80;
}
.FormDonateMoneyInput_input__hHRZW:focus{
    border: 6px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.FormDonateMoneyInput_error__T9Xgt{
    border: 1px solid #EB443F;
}
.FormDonateMoneyInput_dollarCircleOrange__1vapK{
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormDonateMoneyInput.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,4BAA4B;IAC5B,cAAc;IACd,yBAAyB;IACzB,2BAA2B;IAC3B,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,eAAe;AACnB","sourcesContent":[".container{\n    display: inline-block;\n}\n.inputContainer{\n    position: relative;\n}\n.input{\n    height: 16px;\n    padding: 20px 24px 20px 50px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 500px;\n}\n.input::placeholder{\n    color: #1F363D80;\n}\n.input:focus{\n    border: 6px solid #40798C;\n    outline: none;\n    box-shadow: 0 3px 12px 0 #40798C12;\n}\n.error{\n    border: 1px solid #EB443F;\n}\n.dollarCircleOrange{\n    position: absolute;\n    top: 20px;\n    left: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormDonateMoneyInput_container__d+LV3`,
	"inputContainer": `FormDonateMoneyInput_inputContainer__zy7OZ`,
	"input": `FormDonateMoneyInput_input__hHRZW`,
	"error": `FormDonateMoneyInput_error__T9Xgt`,
	"dollarCircleOrange": `FormDonateMoneyInput_dollarCircleOrange__1vapK`
};
export default ___CSS_LOADER_EXPORT___;
