// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_container__YhTn\\+{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.NotFound_errorCode__D8WmS{
    color: #1F363D;
    font-size: 181px;
    font-family: "Gotham Bold", serif;
}
@media(max-width: 520px){
    .NotFound_errorCode__D8WmS{
        font-size: 120px;
    }
}
.NotFound_text__EcWFE{
    margin-top: 20px;
    margin-bottom: 40px;
    color: #1F363D;
    font-size: 16px;
}
.NotFound_image__NR7nB{
    height: calc(100vh - 478px);
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    object-fit: cover;
}
@media(max-width: 520px){
    .NotFound_image__NR7nB{
        height: calc(100vh - 405px);
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFound.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iCAAiC;AACrC;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,eAAe;AACnB;AACA;IACI,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":[".container{\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n}\n.errorCode{\n    color: #1F363D;\n    font-size: 181px;\n    font-family: \"Gotham Bold\", serif;\n}\n@media(max-width: 520px){\n    .errorCode{\n        font-size: 120px;\n    }\n}\n.text{\n    margin-top: 20px;\n    margin-bottom: 40px;\n    color: #1F363D;\n    font-size: 16px;\n}\n.image{\n    height: calc(100vh - 478px);\n    width: 100%;\n    margin-top: 40px;\n    margin-bottom: 20px;\n    object-fit: cover;\n}\n@media(max-width: 520px){\n    .image{\n        height: calc(100vh - 405px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotFound_container__YhTn+`,
	"errorCode": `NotFound_errorCode__D8WmS`,
	"text": `NotFound_text__EcWFE`,
	"image": `NotFound_image__NR7nB`
};
export default ___CSS_LOADER_EXPORT___;
