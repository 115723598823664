// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormInput_container__D2i\\+o{
    display: inline-block;
}
.FormInput_label__-LGEM{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.FormInput_asterisk__H5u0o{
    margin-left: 5px;
    color: #EB443F;
}
.FormInput_inputContainer__3zp9z{
    position: relative;
}
.FormInput_input__o38u8{
    height: 16px;
    padding: 20px 24px 20px 24px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
}
.FormInput_input__o38u8::placeholder{
    color: #1F363D80;
}
.FormInput_password__TfEjD::placeholder{
    position: relative;
    top: 3.5px;
}
.FormInput_input__o38u8:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.FormInput_error__T7Bsf{
    border: 1px solid #EB443F;
}
.FormInput_disabled__AHLOW{
    color: #1F363D80;
}
.FormInput_togglePassword__ZY-rr{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormInput.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,4BAA4B;IAC5B,cAAc;IACd,yBAAyB;IACzB,2BAA2B;IAC3B,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;AACnB","sourcesContent":[".container{\n    display: inline-block;\n}\n.label{\n    display: block;\n    color: #1F363D80;\n    margin-left: 10px;\n    margin-bottom: 8px;\n    font-size: 14px;\n    line-height: 14px;\n}\n.asterisk{\n    margin-left: 5px;\n    color: #EB443F;\n}\n.inputContainer{\n    position: relative;\n}\n.input{\n    height: 16px;\n    padding: 20px 24px 20px 24px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 500px;\n}\n.input::placeholder{\n    color: #1F363D80;\n}\n.password::placeholder{\n    position: relative;\n    top: 3.5px;\n}\n.input:focus{\n    border: 1px solid #40798C;\n    outline: none;\n    box-shadow: 0 3px 12px 0 #40798C12;\n}\n.error{\n    border: 1px solid #EB443F;\n}\n.disabled{\n    color: #1F363D80;\n}\n.togglePassword{\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormInput_container__D2i+o`,
	"label": `FormInput_label__-LGEM`,
	"asterisk": `FormInput_asterisk__H5u0o`,
	"inputContainer": `FormInput_inputContainer__3zp9z`,
	"input": `FormInput_input__o38u8`,
	"password": `FormInput_password__TfEjD`,
	"error": `FormInput_error__T7Bsf`,
	"disabled": `FormInput_disabled__AHLOW`,
	"togglePassword": `FormInput_togglePassword__ZY-rr`
};
export default ___CSS_LOADER_EXPORT___;
