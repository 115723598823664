// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DonationRecord_donation__1OEb6{
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
}
.DonationRecord_donation__1OEb6:not(:last-of-type){
    border-bottom: 1px solid #1F363D1A;
}
.DonationRecord_coin__ja1uS{
    position: relative;
    top: 2.5px;
    height: 12.5px;
    width: 12.5px;
    margin-right: 10px;
}
.DonationRecord_sentence__COiqP{
    color: #1F363D;
    font-size: 14px;
}
.DonationRecord_name__8kqMq{
    font-family: 'Gotham Medium', serif;
}
.DonationRecord_datetime__llh23{
    margin-top: 5px;
    color: #1F363D;
    font-size: 12px;
    opacity: 60%;
}`, "",{"version":3,"sources":["webpack://./src/components/fundraiser/DonationRecord.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,kCAAkC;AACtC;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,mCAAmC;AACvC;AACA;IACI,eAAe;IACf,cAAc;IACd,eAAe;IACf,YAAY;AAChB","sourcesContent":[".donation{\n    display: flex;\n    padding-top: 15px;\n    padding-bottom: 15px;\n}\n.donation:not(:last-of-type){\n    border-bottom: 1px solid #1F363D1A;\n}\n.coin{\n    position: relative;\n    top: 2.5px;\n    height: 12.5px;\n    width: 12.5px;\n    margin-right: 10px;\n}\n.sentence{\n    color: #1F363D;\n    font-size: 14px;\n}\n.name{\n    font-family: 'Gotham Medium', serif;\n}\n.datetime{\n    margin-top: 5px;\n    color: #1F363D;\n    font-size: 12px;\n    opacity: 60%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"donation": `DonationRecord_donation__1OEb6`,
	"coin": `DonationRecord_coin__ja1uS`,
	"sentence": `DonationRecord_sentence__COiqP`,
	"name": `DonationRecord_name__8kqMq`,
	"datetime": `DonationRecord_datetime__llh23`
};
export default ___CSS_LOADER_EXPORT___;
