// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormHeader_header__egRSe{
    color: #1F363D;
    text-align: center;
    font-family: 'Gotham Bold', serif;
    font-size: 42px;
    line-height: 50.4px;
}
@media(max-width: 600px){
    .FormHeader_header__egRSe{
        font-size: 28px;
        line-height: 34px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormHeader.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,iCAAiC;IACjC,eAAe;IACf,mBAAmB;AACvB;AACA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".header{\n    color: #1F363D;\n    text-align: center;\n    font-family: 'Gotham Bold', serif;\n    font-size: 42px;\n    line-height: 50.4px;\n}\n@media(max-width: 600px){\n    .header{\n        font-size: 28px;\n        line-height: 34px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `FormHeader_header__egRSe`
};
export default ___CSS_LOADER_EXPORT___;
