// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormButtonSuccess_button__emZ8-{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 25px;
    color: #396D51;;
    background-color: #9DD1A8;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border: none;
    border-radius: 500px;
    outline: none;
    cursor: default;
}
.FormButtonSuccess_checkmark__Ncwb5{
    margin-left: 8px;
    height: 20px;
    width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormButtonSuccess.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,mCAAmC;IACnC,qBAAqB;IACrB,YAAY;IACZ,oBAAoB;IACpB,aAAa;IACb,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,WAAW;AACf","sourcesContent":[".button{\n    display: flex;\n    position: relative;\n    justify-content: center;\n    align-items: center;\n    height: 48px;\n    padding: 0 25px;\n    color: #396D51;;\n    background-color: #9DD1A8;\n    font-family: 'Gotham Medium', serif;\n    text-decoration: none;\n    border: none;\n    border-radius: 500px;\n    outline: none;\n    cursor: default;\n}\n.checkmark{\n    margin-left: 8px;\n    height: 20px;\n    width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `FormButtonSuccess_button__emZ8-`,
	"checkmark": `FormButtonSuccess_checkmark__Ncwb5`
};
export default ___CSS_LOADER_EXPORT___;
