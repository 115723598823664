// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonLink_buttonLink__RcwYg{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 45px;
    color: #40798C;
    background-color: #FFFFFF;
    font-family: 'Gotham Medium', serif;
    text-decoration: none;
    border-radius: 500px;
    cursor: pointer;
}
.ButtonLink_dark__lafYC{
    color: #FFFFFF;
    background-color: #40798C;
}`, "",{"version":3,"sources":["webpack://./src/components/ButtonLink.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,mCAAmC;IACnC,qBAAqB;IACrB,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".buttonLink{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 48px;\n    padding: 0 45px;\n    color: #40798C;\n    background-color: #FFFFFF;\n    font-family: 'Gotham Medium', serif;\n    text-decoration: none;\n    border-radius: 500px;\n    cursor: pointer;\n}\n.dark{\n    color: #FFFFFF;\n    background-color: #40798C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonLink": `ButtonLink_buttonLink__RcwYg`,
	"dark": `ButtonLink_dark__lafYC`
};
export default ___CSS_LOADER_EXPORT___;
