// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchOrder_container__oTstE{
    position: relative;
    z-index: 50;
}
.SearchOrder_button__9URbg{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 24px;
    padding: 12px 16px;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
    cursor: pointer;
}
.SearchOrder_upDown__nAWJZ{
    height: 24px;
    width: 24px;
    margin-right: 8px;
}
.SearchOrder_downCaret__xGXjf, .SearchOrder_upCaret__OLCqB{
    height: 7px;
    width: 12px;
    margin-left: 10px;
}
.SearchOrder_options__JVy4x{
    position: absolute;
    right: 0;
    width: 139px;
    padding: 3px 18px;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 #00000012;
}
.SearchOrder_option__0PvTj{
    padding-top: 15px;
    padding-bottom: 15px;
    color: #1F363D;
    font-size: 14px;
    cursor: pointer;
}
.SearchOrder_option__0PvTj:not(:last-child){
    border-bottom: 1px solid #1F363D1A;
}
.SearchOrder_selected__ozZlk{
    color: #40798C;
    font-family: 'Gotham Medium', serif;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchOrder.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,2BAA2B;IAC3B,mBAAmB;IACnB,kCAAkC;AACtC;AACA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,cAAc;IACd,eAAe;IACf,eAAe;AACnB;AACA;IACI,kCAAkC;AACtC;AACA;IACI,cAAc;IACd,mCAAmC;AACvC","sourcesContent":[".container{\n    position: relative;\n    z-index: 50;\n}\n.button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    height: 24px;\n    padding: 12px 16px;\n    border: 1px solid #1F363D1A;\n    border-radius: 500px;\n    cursor: pointer;\n}\n.upDown{\n    height: 24px;\n    width: 24px;\n    margin-right: 8px;\n}\n.downCaret, .upCaret{\n    height: 7px;\n    width: 12px;\n    margin-left: 10px;\n}\n.options{\n    position: absolute;\n    right: 0;\n    width: 139px;\n    padding: 3px 18px;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 10px;\n    box-shadow: 0 3px 12px 0 #00000012;\n}\n.option{\n    padding-top: 15px;\n    padding-bottom: 15px;\n    color: #1F363D;\n    font-size: 14px;\n    cursor: pointer;\n}\n.option:not(:last-child){\n    border-bottom: 1px solid #1F363D1A;\n}\n.selected{\n    color: #40798C;\n    font-family: 'Gotham Medium', serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SearchOrder_container__oTstE`,
	"button": `SearchOrder_button__9URbg`,
	"upDown": `SearchOrder_upDown__nAWJZ`,
	"downCaret": `SearchOrder_downCaret__xGXjf`,
	"upCaret": `SearchOrder_upCaret__OLCqB`,
	"options": `SearchOrder_options__JVy4x`,
	"option": `SearchOrder_option__0PvTj`,
	"selected": `SearchOrder_selected__ozZlk`
};
export default ___CSS_LOADER_EXPORT___;
