// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__EwVYi{
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    height: 72px;
    width: 100%;
    color: #1F363D;
    background-color: #FFFFFF;
    font-size: 14px;
    border-top: 1px solid #70A9A11A;
    border-radius: 30px 30px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,YAAY;IACZ,WAAW;IACX,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,+BAA+B;IAC/B,4BAA4B;AAChC","sourcesContent":[".footer{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    bottom: 0;\n    height: 72px;\n    width: 100%;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    font-size: 14px;\n    border-top: 1px solid #70A9A11A;\n    border-radius: 30px 30px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__EwVYi`
};
export default ___CSS_LOADER_EXPORT___;
