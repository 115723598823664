// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormTextEditor_container__7SHxD{
    display: inline-block;
}
.FormTextEditor_label__2\\+KYR{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.FormTextEditor_asterisk__8\\+4zV{
    margin-left: 5px;
    color: #EB443F;
}
.FormTextEditor_textareaContainer__ywU48{
    position: relative;
    height: calc(100% - 22px);
}
.FormTextEditor_textarea__62TYR{
    height: calc(100% - 40px);
    padding: 20px 24px 20px 24px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 28px;
    resize: none;
}
.FormTextEditor_textarea__62TYR::placeholder{
    color: #1F363D80;
}
.FormTextEditor_password__fMdYF::placeholder{
    position: relative;
    top: 3.5px;
}
.FormTextEditor_textarea__62TYR:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.FormTextEditor_charactersCount__N7\\+1u{
    position: absolute;
    bottom: 10px;
    right: 18px;
    color: #1F363D80;
    font-size: 12px;
}
.FormTextEditor_error__Umr8F{
    border: 1px solid #EB443F;
}
.FormTextEditor_togglePassword__k6jFV{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormTextEditor.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,cAAc;IACd,yBAAyB;IACzB,2BAA2B;IAC3B,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;AACnB","sourcesContent":[".container{\n    display: inline-block;\n}\n.label{\n    display: block;\n    color: #1F363D80;\n    margin-left: 10px;\n    margin-bottom: 8px;\n    font-size: 14px;\n    line-height: 14px;\n}\n.asterisk{\n    margin-left: 5px;\n    color: #EB443F;\n}\n.textareaContainer{\n    position: relative;\n    height: calc(100% - 22px);\n}\n.textarea{\n    height: calc(100% - 40px);\n    padding: 20px 24px 20px 24px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 28px;\n    resize: none;\n}\n.textarea::placeholder{\n    color: #1F363D80;\n}\n.password::placeholder{\n    position: relative;\n    top: 3.5px;\n}\n.textarea:focus{\n    border: 1px solid #40798C;\n    outline: none;\n    box-shadow: 0 3px 12px 0 #40798C12;\n}\n.charactersCount{\n    position: absolute;\n    bottom: 10px;\n    right: 18px;\n    color: #1F363D80;\n    font-size: 12px;\n}\n.error{\n    border: 1px solid #EB443F;\n}\n.togglePassword{\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormTextEditor_container__7SHxD`,
	"label": `FormTextEditor_label__2+KYR`,
	"asterisk": `FormTextEditor_asterisk__8+4zV`,
	"textareaContainer": `FormTextEditor_textareaContainer__ywU48`,
	"textarea": `FormTextEditor_textarea__62TYR`,
	"password": `FormTextEditor_password__fMdYF`,
	"charactersCount": `FormTextEditor_charactersCount__N7+1u`,
	"error": `FormTextEditor_error__Umr8F`,
	"togglePassword": `FormTextEditor_togglePassword__k6jFV`
};
export default ___CSS_LOADER_EXPORT___;
