// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormDescription_description__5ElWi{
    color: #1F363D;
    font-size: 16px;
    line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormDescription.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".description{\n    color: #1F363D;\n    font-size: 16px;\n    line-height: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `FormDescription_description__5ElWi`
};
export default ___CSS_LOADER_EXPORT___;
