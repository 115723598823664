// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoggedOutNav_container__RVyx2{
    top: 0;
    width: calc(100% - 70px);
    padding-left: 35px;
    padding-right: 35px;
    background-color: #FFFFFF;
    border: 1px solid #70A9A11A;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.LoggedOutNav_nav__6wQq5{
    display: flex;
    align-items: center;
    height: 93px;
}
@media(max-width: 600px){
    .LoggedOutNav_container__RVyx2{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
    }
    .LoggedOutNav_nav__6wQq5{
        height: 73px;
    }
}
.LoggedOutNav_title__q0VT1{
    color: #274C77;
    font-family: 'Gotham Bold', serif;
    font-size: 24px;
    text-decoration: none;
}
.LoggedOutNav_title__q0VT1 img{
    position: relative;
    top: 3px;
    height: 30px;
    width: 150px;
}
.LoggedOutNav_profileContainer__6PXxS{
    display: flex;
    position: relative;
    margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/logged-out/LoggedOutNav.module.css"],"names":[],"mappings":"AAAA;IACI,MAAM;IACN,wBAAwB;IACxB,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,2BAA2B;IAC3B,4BAA4B;IAC5B,kCAAkC;AACtC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI;QACI,wBAAwB;QACxB,kBAAkB;QAClB,mBAAmB;IACvB;IACA;QACI,YAAY;IAChB;AACJ;AACA;IACI,cAAc;IACd,iCAAiC;IACjC,eAAe;IACf,qBAAqB;AACzB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,YAAY;AAChB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".container{\n    top: 0;\n    width: calc(100% - 70px);\n    padding-left: 35px;\n    padding-right: 35px;\n    background-color: #FFFFFF;\n    border: 1px solid #70A9A11A;\n    border-radius: 0 0 30px 30px;\n    box-shadow: 0 3px 12px 0 #40798C12;\n}\n.nav{\n    display: flex;\n    align-items: center;\n    height: 93px;\n}\n@media(max-width: 600px){\n    .container{\n        width: calc(100% - 40px);\n        padding-left: 20px;\n        padding-right: 20px;\n    }\n    .nav{\n        height: 73px;\n    }\n}\n.title{\n    color: #274C77;\n    font-family: 'Gotham Bold', serif;\n    font-size: 24px;\n    text-decoration: none;\n}\n.title img{\n    position: relative;\n    top: 3px;\n    height: 30px;\n    width: 150px;\n}\n.profileContainer{\n    display: flex;\n    position: relative;\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoggedOutNav_container__RVyx2`,
	"nav": `LoggedOutNav_nav__6wQq5`,
	"title": `LoggedOutNav_title__q0VT1`,
	"profileContainer": `LoggedOutNav_profileContainer__6PXxS`
};
export default ___CSS_LOADER_EXPORT___;
