// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormLastFour_container__mZju4{
    display: inline-block;
}
.FormLastFour_label__F2js6{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.FormLastFour_asterisk__MDZCb{
    margin-left: 5px;
    color: #EB443F;
}
.FormLastFour_inputContainer__3qeN5{
    position: relative;
}
.FormLastFour_input__EROaK{
    height: 16px;
    width: calc(25% - 40px);
    padding: 20px 15px;
    color: #1F363D;
    background-color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    border-top: none;
    border-bottom: 1px solid #40798C66;
    border-left: none;
    border-right: none;
}
.FormLastFour_input__EROaK:not(:last-of-type){
    margin-right: 10px;
}
.FormLastFour_input__EROaK::placeholder{
    color: #1F363D80;
}
.FormLastFour_input__EROaK:focus{
    border-bottom: 1px solid #40798C;
    outline: none;
}
.FormLastFour_error__ZzMf-{
    border: 1px solid #EB443F;
}
.FormLastFour_disabled__W1\\+Qv{
    color: #1F363D80;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormLastFour.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kCAAkC;IAClC,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,gCAAgC;IAChC,aAAa;AACjB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".container{\n    display: inline-block;\n}\n.label{\n    display: block;\n    color: #1F363D80;\n    margin-left: 10px;\n    margin-bottom: 8px;\n    font-size: 14px;\n    line-height: 14px;\n}\n.asterisk{\n    margin-left: 5px;\n    color: #EB443F;\n}\n.inputContainer{\n    position: relative;\n}\n.input{\n    height: 16px;\n    width: calc(25% - 40px);\n    padding: 20px 15px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    text-align: center;\n    font-size: 16px;\n    border-top: none;\n    border-bottom: 1px solid #40798C66;\n    border-left: none;\n    border-right: none;\n}\n.input:not(:last-of-type){\n    margin-right: 10px;\n}\n.input::placeholder{\n    color: #1F363D80;\n}\n.input:focus{\n    border-bottom: 1px solid #40798C;\n    outline: none;\n}\n.error{\n    border: 1px solid #EB443F;\n}\n.disabled{\n    color: #1F363D80;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormLastFour_container__mZju4`,
	"label": `FormLastFour_label__F2js6`,
	"asterisk": `FormLastFour_asterisk__MDZCb`,
	"inputContainer": `FormLastFour_inputContainer__3qeN5`,
	"input": `FormLastFour_input__EROaK`,
	"error": `FormLastFour_error__ZzMf-`,
	"disabled": `FormLastFour_disabled__W1+Qv`
};
export default ___CSS_LOADER_EXPORT___;
