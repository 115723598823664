// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Personal_dualInputsRow__kEGYk{
    display: flex;
    margin-bottom: 15px;
}
.Personal_name__1uJxc input{
    width: 215px;
}
.Personal_singleLineInput__C1LTe{
    display: block !important;
    margin-bottom: 15px;
}
.Personal_singleLineInput__C1LTe input{
    width: calc(100% - 50px);
}
@media(max-width: 930px){
    .Personal_dualInputsRow__kEGYk{
        display: block;
        margin-bottom: 0;
    }
    .Personal_name__1uJxc{
        display: block;
        margin-bottom: 15px;
    }
    .Personal_name__1uJxc input{
        width: calc(100% - 50px);
    }
}
.Personal_button__gwY56{
    width: 261px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/Personal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,wBAAwB;AAC5B;AACA;IACI;QACI,cAAc;QACd,gBAAgB;IACpB;IACA;QACI,cAAc;QACd,mBAAmB;IACvB;IACA;QACI,wBAAwB;IAC5B;AACJ;AACA;IACI,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".dualInputsRow{\n    display: flex;\n    margin-bottom: 15px;\n}\n.name input{\n    width: 215px;\n}\n.singleLineInput{\n    display: block !important;\n    margin-bottom: 15px;\n}\n.singleLineInput input{\n    width: calc(100% - 50px);\n}\n@media(max-width: 930px){\n    .dualInputsRow{\n        display: block;\n        margin-bottom: 0;\n    }\n    .name{\n        display: block;\n        margin-bottom: 15px;\n    }\n    .name input{\n        width: calc(100% - 50px);\n    }\n}\n.button{\n    width: 261px;\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dualInputsRow": `Personal_dualInputsRow__kEGYk`,
	"name": `Personal_name__1uJxc`,
	"singleLineInput": `Personal_singleLineInput__C1LTe`,
	"button": `Personal_button__gwY56`
};
export default ___CSS_LOADER_EXPORT___;
