// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormMoneyInput_container__Ehosb{
    display: inline-block;
}
.FormMoneyInput_label__L3MBv{
    display: block;
    color: #1F363D80;
    margin-left: 10px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
}
.FormMoneyInput_asterisk__zu39j{
    margin-left: 5px;
    color: #EB443F;
}
.FormMoneyInput_inputContainer__cjXr\\+{
    position: relative;
}
.FormMoneyInput_input__FMlhf{
    height: 16px;
    padding: 20px 24px 20px 55px;
    color: #1F363D;
    background-color: #FFFFFF;
    border: 1px solid #1F363D1A;
    border-radius: 500px;
}
.FormMoneyInput_input__FMlhf::placeholder{
    color: #1F363D80;
}
.FormMoneyInput_input__FMlhf:focus{
    border: 1px solid #40798C;
    outline: none;
    box-shadow: 0 3px 12px 0 #40798C12;
}
.FormMoneyInput_error__L6b0f{
    border: 1px solid #EB443F;
}
.FormMoneyInput_money__JzmUs{
    position: absolute;
    top: 15px;
    left: 20px;
    height: 24px;
    width: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/form/FormMoneyInput.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,4BAA4B;IAC5B,cAAc;IACd,yBAAyB;IACzB,2BAA2B;IAC3B,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,kCAAkC;AACtC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;AACf","sourcesContent":[".container{\n    display: inline-block;\n}\n.label{\n    display: block;\n    color: #1F363D80;\n    margin-left: 10px;\n    margin-bottom: 8px;\n    font-size: 14px;\n    line-height: 14px;\n}\n.asterisk{\n    margin-left: 5px;\n    color: #EB443F;\n}\n.inputContainer{\n    position: relative;\n}\n.input{\n    height: 16px;\n    padding: 20px 24px 20px 55px;\n    color: #1F363D;\n    background-color: #FFFFFF;\n    border: 1px solid #1F363D1A;\n    border-radius: 500px;\n}\n.input::placeholder{\n    color: #1F363D80;\n}\n.input:focus{\n    border: 1px solid #40798C;\n    outline: none;\n    box-shadow: 0 3px 12px 0 #40798C12;\n}\n.error{\n    border: 1px solid #EB443F;\n}\n.money{\n    position: absolute;\n    top: 15px;\n    left: 20px;\n    height: 24px;\n    width: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormMoneyInput_container__Ehosb`,
	"label": `FormMoneyInput_label__L3MBv`,
	"asterisk": `FormMoneyInput_asterisk__zu39j`,
	"inputContainer": `FormMoneyInput_inputContainer__cjXr+`,
	"input": `FormMoneyInput_input__FMlhf`,
	"error": `FormMoneyInput_error__L6b0f`,
	"money": `FormMoneyInput_money__JzmUs`
};
export default ___CSS_LOADER_EXPORT___;
