// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Security_singleLineInput__jTRrQ{
    display: block !important;
    margin-bottom: 15px;
}
.Security_singleLineInput__jTRrQ input{
    width: calc(100% - 50px);
}
.Security_button__3Pm1l{
    width: 261px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/Security.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,YAAY;IACZ,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".singleLineInput{\n    display: block !important;\n    margin-bottom: 15px;\n}\n.singleLineInput input{\n    width: calc(100% - 50px);\n}\n.button{\n    width: 261px;\n    left: 0;\n    right: 0;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"singleLineInput": `Security_singleLineInput__jTRrQ`,
	"button": `Security_button__3Pm1l`
};
export default ___CSS_LOADER_EXPORT___;
